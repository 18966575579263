/* eslint-disable @nx/enforce-module-boundaries */
import {
  Card,
  Stack,
  HStack,
  Text,
  Spinner,
  VStack,
  Image,
} from '@mybridge/ui';
import { forwardRef, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DashboardPostBox } from 'v4/snippets/dashboard/feed/post-box';
import { SearchContext } from '../../context';
import { SearchCardSkeleton } from '../../search-card-skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';

export const SearchPostsListing = forwardRef(
  ({ count = 0, listing, isMainList, ...props }, ref) => {
    const {
      postsSearchListing,
      refetchPostsSearch,
      postsSearchIsFetching,
      search,
      activeTab,
      postsFetchNextPage,
      postsHasNextPage,
      postsTotalCount,
      postsSearchIsLoading,
    } = useContext(SearchContext);

    const { loggedIn } = useSelector((s) => s.user);

    useEffect(() => {
      if (!listing && activeTab === 'posts') {
        refetchPostsSearch?.();
      }
    }, [search, activeTab, listing]);

    const list = listing ?? postsSearchListing;
console.log();
    return (
      <>
        <Stack mt={0} spacing={4}>
          {isMainList && list?.length > 0 ? (
            <>
              {list?.map?.((li, liIndex) => (
                <Card
                  key={liIndex}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  border="1px solid"
                  borderColor="brandGray.200"
                  flex={1}
                  p={0}
                >
                  {/* Pass the author's display name as a prop */}
                  <DashboardPostBox post={li} authorDisplayName={li?.author?.display_name} />
                </Card>
              ))}
            </>
          ) : (
            <>
              {postsSearchIsLoading ? (
                <SearchCardSkeleton />
              ) : (
                <>
                  {postsTotalCount > 0 ? (
                    <InfiniteScroll
                      dataLength={list?.length}
                      next={postsFetchNextPage}
                      hasMore={!!postsHasNextPage}
                      loader={
                        <HStack p={4} justifyContent="center" w="100%">
                          <Spinner />
                        </HStack>
                      }
                    >
                      {list?.map?.((li, liIndex) => (
                        <Card
                          key={liIndex}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          borderTop="1px solid"
                          borderColor="#EFF3F4"
                          flex={1}
                          p={0}
                          borderRadius={"none"}
                          // mb={4}
                        >
                          {/* Pass the author's display name as a prop */}
                          <DashboardPostBox post={li} authorDisplayName={li?.author?.display_name} />
                        </Card>
                      ))}
                    </InfiniteScroll>
                  ) : (
                    <VStack
                justifyContent="center"
                alignItems="center"
                h="calc(80vh - 64px)"
              >
                <Image
                    src="/images/defaultSearchImage.svg"
                    alt="default Image"
                    width={["80%", "400px"]}
                  />
                <Text fontSize="18px" color="#AFAFAF" fontWeight="500">No results found</Text>
                <Text fontSize="14px" color="#AFAFAF" fontWeight="500">Try shortening or rephrasing your search.</Text>
              </VStack>
                  )}
                </>
              )}
            </>
          )}
        </Stack>
      </>
    );
  }
);
